import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import StartMeeting from './start_meeting';
import JoinMeeting from './join_meeting';
import Scheduler from './scheduler';
import UpcomingMeeting from './upcoming_meeting';
import MeetingHistory from './meeting_history';


export default function UserDashboard() {
    const navigate = useNavigate();
    const [compo, setComp] = useState(<StartMeeting />);

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate("/login");
        }
    }, []);

    let jwt = "";
    let meetingId = "";
    let name = "";

    jwt = localStorage.getItem('token');
    meetingId = localStorage.getItem('meetingId');
    name = localStorage.getItem('name');
    console.log("Token : " + jwt);

    // const handleUserTab = (temp) => {
    //     console.log("This is test");
    // }

    return (
        <div className='container-fluid user-container border-top'>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <ol>
                        <li><a href="/app">Home</a></li>
                        <li>Dashboard</li>
                    </ol>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <h4 className='my-4'>Meeting Dashboard</h4>
                        <div className="nav-area">
                        {/* <ul className="nav flex-column" id="myTab" role="tablist"> */}
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="start-meeting-tab" data-bs-toggle="tab" href="#start-meeting" role="tab" aria-controls="meeting-history" aria-selected="true" ><i class="fas fa-play-circle"></i> <span>Start Meeting</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="join-meeting-tab" data-bs-toggle="tab" href="#join-meeting" role="tab" aria-controls="address" aria-selected="false" ><i class="fas fa-video"></i> <span>Join Meeting</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="schedule-meetings-tab" data-bs-toggle="tab" href="#schedule-meeting" role="tab" aria-controls="address" aria-selected="false" ><i class="fas fa-calendar-alt"></i> <span>Schedule Meeting</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="change-meeting-tab" data-bs-toggle="tab" href="#upcoming-meeting" role="tab" aria-controls="address" aria-selected="false" ><i class="fas fa-clock"></i> <span>Upcoming Meeting</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="history-tab" data-bs-toggle="tab" href="#meeting-history" role="tab" aria-controls="address" aria-selected="false" ><i class="fas fa-history"></i> <span>Meeting History</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active pt-3" id="start-meeting" role="tabpanel" aria-labelledby="my-order">
                            <StartMeeting />
                        </div>
                        <div className="tab-pane fade pt-3" id="join-meeting" role="tabpanel" aria-labelledby="my-address">
                            <JoinMeeting />
                        </div>
                        <div className="tab-pane fade pt-3" id="schedule-meeting" role="tabpanel" aria-labelledby="my-address">
                            <Scheduler />
                        </div>
                        <div className="tab-pane fade pt-3" id="upcoming-meeting" role="tabpanel" aria-labelledby="my-order">
                            <UpcomingMeeting />
                        </div>
                        <div className="tab-pane fade pt-3" id="meeting-history" role="tabpanel" aria-labelledby="my-order">
                            <MeetingHistory />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
