import React from 'react'
import { useNavigate } from "react-router-dom";
import Join_Meeting_Icon from "../resources/join_meeting_icon.png";
import { useState } from "react";
import axios from "axios";

export default function JoinMeeting() {

  const [meetingId, setMeetingId] = useState("");
  const [passcode, setPasscode] = useState("");
  const [flag, setFlag] = useState(false);

  const navigate = useNavigate();

  const HandleSubmit = async (event) => {
    event.preventDefault();

    let mId = meetingId.replace(/ /g, '');
    const url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GUEST_JOIN_MEETING_URL + '?' + 'meetingID=' + mId + '&passcode=' + passcode;
    console.log("URL " + url);

    axios.post(url).then(response => {
      console.log(" Join meeting response: " + JSON.stringify(response, null, 2));
      window.open(response.data.url, "_blank");
      navigate("/join_meeting_success");

    }).catch(error => {
      console.log("Error in request " + JSON.stringify(error, null, 2));
      setFlag(true);
    });
  }

  return (
    <div>
      <div className="container">
        <h4 class="pb-4">Join a meeting right away</h4>
        <form onSubmit={HandleSubmit}>
          <div class="row col-md-8">
            <div className="form-group row">
              <label for="meetingId" class="col-sm-2">Meeting ID</label>
              <div class="col-sm-10">
                <input type="text" name="meetingId" className="form-control shadow-none" onChange={(e) => setMeetingId(e.target.value)} 
                  placeholder="Enter 12 digits meeting ID" required />
              </div>
            </div>

            <div className="form-group row">
              <label for="passcode" class="col-sm-2">Passcode</label>
              <div class="col-sm-10">
                <input type="text" name="passcode" className="form-control shadow-none" onChange={(e) => setPasscode(e.target.value)} 
                  maxLength={4} placeholder="Enter passcode here" required />
                {flag && <span className="text-danger">Meeting ID or passcode missmatch </span>}
              </div>
            </div>

            <div className="form-group row">
              <label for="button" class="col-sm-2"></label>
              <div class="col-sm-10">
                <button type="submit" className="btn btn-success" >
                  Join in Meeting
                </button>
              </div>
            </div>

          </div>
        </form>

      </div>
    </div>)
}
