import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Scheduler_Icon from "../resources/scheduler_icon.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Scheduler() {
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate("/login");
        }
    }, [])

    const [passcode, setPasscode] = useState("");
    const [meetingDate, setInputDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [title, setTitle] = useState("");
    const [error, setError] = useState("");
    const [showForm, setShowForm] = useState("");
    const [meetingID, setMeetingID] = useState("");
    const [invitationLink, setInvitationLink] = useState("");
    const currentDate = new Date().toISOString().slice(0, 10);
    const currentTime = new Date().toTimeString().slice(0, 5);
    console.log("currentTime :" + currentTime);

    const [meetingDuration, setMeetingDuration] = useState("");


    const jwt = localStorage.getItem('token');

    const header = {
        headers: {
            'Authorization': 'Bearer ' + jwt
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        setError("");
        const validationError = validateForm();
        if (Object.keys(validationError).length === 0) {
            console.log("Scheduler Form Data : No errors in data validation");
        } else {
            setError(validationError);
            return;
        }

        const upcomingMeetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPDATE_UPCOMING_MEETING_URL;

        await axios.post(upcomingMeetingUrl,
            {
                title,
                passcode,
                meetingDate,
                startTime,
                endTime
            }, header).then(response => {
                console.log(" Response  : " + JSON.stringify(response, null, 2));
                setMeetingID(response.data.meetingID);
                setInvitationLink(response.data.invitationLink);
                setShowForm("false");
            }).catch(error => {
                console.error("Error in registration + " + JSON.stringify(error, null, 2));
                if (error.response.status == "401") {
                    localStorage.removeItem("token");
                    navigate("/login");
                }
            })

    }

    const handleFlag = async (event) => {
        event.preventDefault();
        setShowForm("");
        console.log("handleFlag");
    }

    const validateForm = () => {
        const validationError = {};
        if (!passcode) {
            validationError.passcode = "Passcode is required";
        }
        if (!title) {
            validationError.title = "Meeting title is required";
        }
        if (!meetingDate) {
            validationError.meetingDate = "Date is required";
        }
        if (!startTime) {
            validationError.startTime = "Start time is required";
        }
        if (!endTime) {
            validationError.endTime = "End time is required";
        }
        return validationError;
    }

    const setTime = (event) => {
        let sTime = event.target.value;

        console.log('Start Time ---> ' + sTime);

        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();

        const [hours, minutes] = sTime.split(':');
        const inputHoursInt = parseInt(hours, 10);
        const inputMinutesInt = parseInt(minutes, 10);


        if ((currentDate === meetingDate && inputHoursInt < currentHours) || (currentDate === meetingDate && inputHoursInt === currentHours && inputMinutesInt <= currentMinutes)) {
            alert('Please enter a future time.');
            setStartTime(''); // Clear the input field
        } else {
            setStartTime(event.target.value);
            let newHours = ((inputHoursInt + 1) % 24);
            let newMinutes = inputMinutesInt;
            const endTime = String(newHours).padStart(2, '0') + ":" + String(newMinutes).padStart(2, '0');
            console.log("End Time -->" + endTime);
            setEndTime(endTime);
        }
    }


    const setTimeEnd = (event) => {
        setEndTime(prevEndTime => !prevEndTime);
        let eTime = event.target.value;
        console.log("eTime " + eTime);

        const [hours, minutes] = eTime.split(':');
        const endHoursInt = parseInt(hours, 10);
        const endMinutesInt = parseInt(minutes, 10);

        const [hr, min] = startTime.split(':');
        const startHoursInt = parseInt(hr, 10);
        const startMinutesInt = parseInt(min, 10);

        if (endHoursInt < startHoursInt || (endHoursInt === startHoursInt && endMinutesInt <= startMinutesInt)) {
            alert('Please enter a future time.');
            setEndTime(''); // Clear the input field
        } else {
            setEndTime(event.target.value);
            console.log(endTime);
        }
    }

    const [copyInvitLink, setcopyInvitLink] = useState('');

    const copyInvitationLink = () => {
        let copyInvitL = " Meeting Id : " + meetingID + "\n\n Passcode : " + passcode + "\n\n Invitation Link : " + invitationLink;
        setcopyInvitLink(copyInvitL);
        navigator.clipboard.writeText(copyInvitL);
        console.log(copyInvitL);
        toast.success('Meeting Info copied', { autoClose: 2000 })
    }

    return (
        <div className="container">
            {/* <div className="row">                 */}
            <div className="col-md-12 ">
                <h4 class="pb-4">Schedule a meeting</h4>
                {!showForm &&
                    <form onSubmit={handleSubmit}>
                        <div className="form-group schedulerForm col-md-8">

                            <div className="form-group row">
                                <label for="passcode" class="col-sm-2">Passcode</label>
                                <div class="col-sm-10">
                                    <input type="text" className="form-control shadow-none"
                                        id="passcode" placeholder="Passcode only digit" onChange={(e) => setPasscode(e.target.value)}
                                        value={passcode} maxLength={4} minLength={4} pattern="\d*" />
                                </div>
                            </div>
                            <div className="text-right ">{error.passcode && <span className="text-danger">{error.passcode}</span>}</div>

                            <div className="form-group row">
                                <label for="title" class="col-sm-2">Meeting Title</label>
                                <div class="col-sm-10">
                                    <input type="text" className="form-control shadow-none" id="title"
                                        placeholder="Meeting Title" onChange={(e) => setTitle(e.target.value)}
                                        value={title} />
                                </div>
                            </div>
                            <div className="text-right "> {error.title && <span className="text-danger">{error.title}</span>}</div>

                            <div className="form-group row">
                                <label for="dateInput" class="col-sm-2">Meeting Date</label>
                                <div class="col-sm-10">
                                    <input type="date" className="form-control shadow-none"
                                        id="dateInput" min={currentDate}
                                        onChange={(e) => setInputDate(e.target.value)} value={meetingDate} />
                                </div>
                            </div>
                            <div className="text-right ">{error.meetingDate && <span className="text-danger">{error.meetingDate}</span>}</div>

                            <div className="form-group row">
                                <label for="time" class="col-sm-2">Start Time</label>
                                <div class="col-sm-10">
                                    <input type="time" className="form-control shadow-none" onChange={setTime}
                                        value={startTime} required />
                                </div>
                            </div>
                            <div className="text-right ">{error.startTime && <span className="text-danger text-center">{error.startTime}</span>}</div>

                            <div className="form-group row">
                                <label for="endTimeInput" class="col-sm-2">End Time</label>
                                <div class="col-sm-10">
                                    <input type="time" className="form-control shadow-none" id="endTimeInput"
                                        onChange={setTimeEnd} value={endTime} required />
                                </div>
                            </div>
                            <div className="text-right">
                                {error.endTime && <span className="text-danger">{error.endTime}</span>}
                                <label className="">{meetingDuration}</label>
                            </div>

                            <div className="form-group row">
                                <label for="button" class="col-sm-2"></label>
                                <div class="col-sm-10">
                                    <button type="submit" className="btn btn-success" >Schedule the meeting</button>
                                </div>
                            </div>

                        </div>
                    </form>
                }
                <ToastContainer />
                {showForm &&
                    <div className="schedulerForm col-sm-12 d-flex">

                        <div className="centerImage col-sm-3">
                            <img src={Scheduler_Icon} width="200px" height="200px" />
                            <div className="py-2">Meeting Scheduled Successfully </div>
                        </div>
                        <div class="col-sm-9">
                            <div class="col-md-12 d-flex py-2">
                                <div class="col-md-3">
                                    <div class="form-label">Meeting ID</div>
                                </div>
                                <div class="col-md-9">
                                    <div class="col-sm-12">{meetingID}</div>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex py-2">
                                <div class="col-md-3">
                                    <div class="form-label">Meeting Title</div>
                                </div>
                                <div class="col-md-9">
                                    <div class="col-sm-12">{title}</div>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex py-2">
                                <div class="col-md-3">
                                    <div class="form-label">Passcode</div>
                                </div>
                                <div class="col-md-9">
                                    <div class="col-sm-12">{passcode} </div>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex py-2">
                                <div class="col-md-3">
                                    <div class="form-label">Meeting Date</div>
                                </div>
                                <div class="col-md-9">
                                    <div class="col-sm-12">{meetingDate}</div>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex py-2">
                                <div class="col-md-3">
                                    <div class="form-label">Start Time</div>
                                </div>
                                <div class="col-md-9">
                                    <div class="col-sm-12">{startTime} </div>
                                </div>
                            </div>

                            <div class="col-md-12 d-flex py-2">
                                <div class="col-md-3">
                                    <div class="form-label">End Time</div>
                                </div>
                                <div class="col-md-9">
                                    <div class="col-sm-12">{endTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} </div>
                                </div>
                            </div>
                            <div class="col-md-12 d-flex py-2">
                                <div class="col-md-3">
                                    <div class="form-label"></div>
                                </div>
                                <div class="col-md-9">
                                    <div class="col-sm-12">
                                        <div className="py-2">
                                            <button className="btn btn-primary" onClick={copyInvitationLink}>Copy Meeting Info</button>
                                            &nbsp;&nbsp;Schedule <a href="" onClick={handleFlag}>another meeting  </a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
