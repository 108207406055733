import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Join_Meeting_Icon from "../resources/join_meeting_icon.png";
import axios from 'axios';
import { useRef } from 'react';
// import 'e:/Boithok/Code/boithok-um-ui/node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

export default function Home() {

  const modalRef = useRef(null);
  const [meetingId, setMeetingId] = useState("");
  const [passcode, setPasscode] = useState("");

  const [mobileNo, setMobileNo] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [showContactForm, setShowContactForm] = useState(true);
  const [contactMassage, setContactMessage] = useState("");

  const navigate = useNavigate();

  let jwt = localStorage.getItem('token');

  const header = {
    headers: {
      'Authorization': 'Bearer ' + jwt
    }
  }
  console.log("Authorization Meeting History" + header);
  const purchase_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PAYMENT_API;
  const contact_url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CONTACT_API;
  console.log("Purchase url : " + purchase_url);

  const HandleGuestJoinMeetingSubmit = async (event) => {
    event.preventDefault();
    let mId = meetingId.replace(/ /g, '');

    const guestMeetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GUEST_JOIN_MEETING_URL + '?' + 'meetingID=' + mId + '&passcode=' + passcode;
    console.log("guestMeetingUrl: " + guestMeetingUrl);
    axios.post(guestMeetingUrl).then(response => {
      console.log(" Join meeting response: " + JSON.stringify(response, null, 2));

      //   // Close the modal programmatically before opening the new tab
      //  const modalElement = document.getElementById('exampleModal');
      //   const modalInstance = new bootstrap.Modal(modalElement);
      //   modalInstance.hide();  // Close the modal

      window.open(response.data.url, "_blank");
      navigate("/join_meeting_success");
    }).catch(error => {
      console.log("Error in request " + JSON.stringify(error, null, 2));
    });
  }

  const HandleContactFormSubmit = async (event) => {
    event.preventDefault();
    let data = new FormData();
    data.append('mobile', mobileNo);
    data.append('name', name);
    data.append('email', email);
    data.append('message', message);
    data.append('subject', subject);
    console.log("contact url: " + contact_url);
    axios.post(contact_url, data)
      .then(response => {
        console.log("Response data : " + JSON.stringify(response, null, 2));
        if (response.status == 200) {
          setContactMessage("Your message has beed sent successfully");
          setShowContactForm(false);
        }
      }).catch(error => {
        console.error("Error in contact + " + JSON.stringify(error, null, 2));
        setContactMessage("There is a error in server");
        setShowContactForm(false);
      })
  }

  function purchasePackage(id) {
    let p_url = purchase_url + id;
    console.log("Purchase url---> : " + p_url);
    axios.get(p_url, header)
      .then(response => {
        console.log(" URL " + response.data.url);
        window.open(response.data.url, "_self");
        console.log("Success ====>  " + JSON.stringify(response, null, 2));
        console.log('Result ' + response);
      })
      .catch(error => {
        console.log("Request is not get any response");
        console.log("***************** Error Here : " + JSON.stringify(error, null, 2));
        if (error.response.status == "401") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      })
  }
  const listyle = {
    color: "#3F8755"
  }
  const meetingUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_START_MEETING;
  const startMeeting = (e) => {
    console.log(jwt);
    console.log("Meeting url " + meetingUrl);
    console.log("header " + JSON.stringify(header));

    axios.get(meetingUrl, header)
      .then(response => {
        console.log(" Response : " + JSON.stringify(response));
        console.log(" Response : " + response.data.url);
        window.open(response.data.url, "_blank");
      }).catch(error => {
        console.log(" Error Here : " + JSON.stringify(error, null, 2));
        console.log(error.response.status);
        if (error.response.status == "401") {
          localStorage.removeItem("token");
          navigate("/login");
        }

      })
  }

  return (
    <div>
      {/* <div class="hero-header overflow-hidden px-5"> */} 
      <div class="container hero-header overflow-hidden">
        <div class="rotate-img">
          <img src={`${process.env.PUBLIC_URL}/assets/img/sty-1.png`} class="img-fluid w-100" alt=""></img>
          <div class="rotate-sty-2"></div>
        </div>
        <div class="row gy-5 align-items-center">
          <div class="col-lg-6 wow fadeInLeft" data-wow-delay="0.1s">
            <h1 class="display-5 text-dark mb-4 wow fadeInUp" data-wow-delay="0.3s">Join or Start Meeting Securly & Instantly </h1>
            <p class="fs-4 mb-4 wow fadeInUp" data-wow-delay="0.5s">Streamline Communication, Increase employee engagement & Improve Productivity with Boithok</p>

            <div class="row gy-2 justify-content-start">
              <div class="col-12 col-md-4">
                <a href="#" class="btn btn-primary d-block rounded-pill py-3 wow fadeInUp" data-bs-toggle="modal" data-bs-target="#exampleModal">Join Meeting</a>
              </div>
              <div class="col-12 col-md-4">
                <a href="#" class="btn btn-secondary d-block rounded-pill py-3 wow fadeInUp" onClick={startMeeting}>Start Meeting</a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 wow fadeInRight" data-wow-delay="0.2s">
            <img src={`${process.env.PUBLIC_URL}/assets/img/conference.png`} class="img-fluid w-100 h-100" alt=""></img>
          </div>
        </div>
      </div>
      {/* <section className="">
          <div class=" ">
            <div class="row gx-5">
              <h2 class="">About Boithok</h2>
              <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="RotateMoveLeft">
                  <img src="assets/img/wsis-cert.jpeg" class="img-fluid w-100" alt="" />
                </div>
              </div>
              <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
               
                <p class="mb-4 text-justify">Boithok is an award-winning, web-based video conferencing platform hosted at the National Data Center and maintained by the BNDA team. It enables users to share their needs and thoughts through secure, real-time video conferencing, creating a realistic virtual meeting experience. The platform ensures complete security and confidentiality of user data, with no data or recordings stored outside the country. Boithok is also highly user-friendly, allowing users to join meetings effortlessly by clicking a meeting link within seconds.
                </p>
                <ul class="no-bullets">
                  <li><i class="fas fa-check-circle" style={listyle}></i>  Running meetings for unlimited duration.</li>
                  <li><i class="fas fa-check-circle" style={listyle}></i>  Hosted in our own Data Center.</li>
                  <li><i class="fas fa-check-circle" style={listyle}></i>  Recordings are saved safely, can be downloaded at any time.</li>
                  <li><i class="fas fa-check-circle" style={listyle}></i>  All necessary features for a video conferencing are present.</li>
                  <li><i class="fas fa-check-circle" style={listyle}></i>  User can participate in meetings without prior registration.</li>
                </ul>
              </div>
            </div>
            <div class="row g-5">
              <div> Ministry of foreign affairs, ICT Division, BCC, A2I and some other govt. offices are using Boithok on regular basis. Honorable ICT state minister instructed to extend its usage across government offices after incorporating user comments and feedback. Around 2500+ successful meetings has already been held at Boithok platform so far.
              </div>
            </div>

            <div className="services row g-5">
              <div className="section-title" data-aos="fade-up">
                <h2>Features</h2>
                <p>Boithok is a feature-riched video conferencing solution that helps you to stay connected & collaboration with team members securely and efficiently</p>
              </div>

              <div className="row">
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in">
                  <div className="icon-box icon-box-pink">
                    <div className="icon"><i className="bx bxl-dribbble"></i></div>
                    <h4 className="title"><a href="">High-Quality Video</a></h4>
                    <p className="description">Experience crystal-clear video and audio for your meetings, presentations, and more.</p>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="100">
                  <div className="icon-box icon-box-cyan">
                    <div className="icon"><i className="bx bx-file"></i></div>
                    <h4 className="title"><a href="">Collaboration</a></h4>
                    <p className="description">Easily collaborate with your team members, share content, and work together in real-time.</p>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                  <div className="icon-box icon-box-green">
                    <div className="icon"><i className="bx bx-tachometer"></i></div>
                    <h4 className="title"><a href="">Secure and Private</a></h4>
                    <p className="description">We prioritize the security of your conversations with top-notch encryption and privacy measures.</p>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                  <div className="icon-box icon-box-blue">
                    <div className="icon"><i className="bx bx-mobile-alt"></i></div>
                    <h4 className="title"><a href="">Mobile Apps</a></h4>
                    <p className="description">Stay connected on the go with our feature-rich mobile apps for iOS and Android.</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                  <div className="icon-box icon-box-blue">
                    <div className="icon"><i className="bx bx-video-recording"></i></div>
                    <h4 className="title"><a href="">Recording</a></h4>
                    <p className="description">Record your meetings and webinars for future reference or sharing with others..</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                  <div className="icon-box icon-box-green">
                    <div className="icon"><i className="bx bx-share"></i></div>
                    <h4 className="title"><a href="">Screen Sharing</a></h4>
                    <p className="description">Share your screen during meetings and presentations for effective communication.</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                  <div className="icon-box icon-box-cyan">
                    <div className="icon"><i className="bx bx-group"></i></div>
                    <h4 className="title"><a href="">Webinars Hosting</a></h4>
                    <p className="description">Host webinars with ease, engage with your audience, and conduct Q&A sessions..</p>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                  <div className="icon-box icon-box-pink">
                    <div className="icon"><i className="bx bx-list-check"></i></div>
                    <h4 className="title"><a href="">Attendance</a></h4>
                    <p className="description">Get the Participants list of each meeting..</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Join in a meeting</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={HandleGuestJoinMeetingSubmit}>
              <div className="modal-body invitaion-info text-center">
                <img src={Join_Meeting_Icon} height="120" width="120" className="p-3 " />
                <div className="row px-4">
                  <input type="text" className="form-control shadow-none" value={meetingId} onChange={(e) => setMeetingId(e.target.value)} placeholder="Enter meeting ID (12 digits)" required pattern="\d{12}" />
                </div>
                <div className="row px-4">
                  <input type="text" className="form-control shadow-none" value={passcode} onChange={(e) => setPasscode(e.target.value)} placeholder="Enter passcode (4 digits)" required pattern="\d{4}" />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary">Join Meeting</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
