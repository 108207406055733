import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

export default function Footer() {
  return (
    <footer id="footer">
      {/* <div class="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s"> */}
      <div class="container footer py-5 wow fadeIn" data-wow-delay="0.2s">
        <div class="px-4 py-5">
          <div class="row g-5">
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="footer-item d-flex flex-column">
                <h4 class="text-dark mb-4">Site Map</h4>
                <Link to="/"> Home</Link>
                <Link to="/about"> About Boithok</Link>
                {/* <a href="/features"> Features</a> */}
                <Link to="/faq"> FAQ</Link>
                <Link to="/login"> Login</Link>
                <Link to="/registration"> Registration</Link>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="footer-item d-flex flex-column">
                <h4 class="mb-4 text-dark">Quick Links</h4>
                <a href="https://bcc.gov.bd"> Bangladesh Computer Council</a>
                <a href="https://ictd.gov.bd"> ICT Division</a>
                <a href=""> Privacy Policy</a>
                <a href=""> Terms & Conditions</a>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="footer-item d-flex flex-column">
                <h4 class="mb-4 text-dark">Mobile Apps</h4>             
                <input
                  type="image"
                  style={{width: "150px"}}
                  // src={`${process.env.PUBLIC_URL}/assets/img/google-play-badge.png`}
                  src={`${process.env.PUBLIC_URL}/assets/img/android.png`}
                  alt="Google Play App Link"
                  title="Google Play App Link"
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=bd.gov.bcc.vc',
                      '_blank'
                    )
                  }
                />
                <br/>
                <input
                  type="image"
                  style={{width: "150px"}}
                  // src={`${process.env.PUBLIC_URL}/assets/img/app-store-badge.png`}
                  src={`${process.env.PUBLIC_URL}/assets/img/ios-app.png`}
                  alt="iPhone App Link"
                  title="iPhone App Link"
                  onClick={() =>
                    window.open(
                      'https://apps.apple.com/us/app/boithok/id1547227186',
                      '_blank'
                    )
                  }
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-3">
              <div class="footer-item d-flex flex-column">
                <h4 class="mb-4 text-dark">Contact Info</h4>
                <img src={`${process.env.PUBLIC_URL}/assets/img/bcc-logo-h.png`} alt="logo" width="150px" heigth="100%" style={{ marginBottom: "10px" }} />
                <p><i class="fa fa-map-marker-alt me-2"></i> E-14/X, BCC Bhaban (ICT Tower) <br />
                  Agargaon Dhaka-1207, Bangladesh <br /></p>
                {/* <strong><i class="fas fa-envelope me-2"></i> info@boithok.com</strong> */}
                {/* <strong><i class="fas fa-phone me-2"></i> +01911707370</strong>
                <strong class="mb-3"><i class="fas fa-print me-2"></i> +012 345 67890</strong> */}
                {/* <div class="d-flex align-items-center">
                  <i class="fas fa-share fa-2x text-secondary me-2"></i>
                  <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                  <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                  <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                  <a class="btn-square btn btn-primary rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="#" class="btn btn-primary btn-lg-square back-to-top"><i class="fa fa-arrow-up"></i></a>
    </footer>
  );
}
