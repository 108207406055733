import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

export default function Navbar() {

  const navigate = useNavigate();

  const [navVisible, setNavVisible] = useState(true);

  // const handleMenuClick = () => {
  //   setNavVisible(!navVisible);
  // };

  const handleLogout = (e) => {
    localStorage.removeItem("token");
    navigate("/login");
  }

  const [navMenuClassName, setNavMenuClassName] = useState('navbar order-last order-lg-0');
  const [listMenuClassName, setListMenuClassName] = useState('bi mobile-nav-toggle bi-list');

  const divider = {
    height: "1px",          /* Divider thickness */
    margin: "0.5rem 0",     /* Vertical spacing */
    overflow: "hidden",     /* Ensures clean layout */
    backgroundColor: "#e9ecef",  /* Light gray color */
    borderTop: "1px solid #dee2e6" /* Slight border for effect */
  }

  return (
    <>      
      {/* <div class="container-fluid header position-relative  p-0"> */}
      <div class="header position-relative  p-0">
      {/* <nav class="navbar navbar-expand-lg fixed-top navbar-light px-4 px-lg-5 py-3 py-lg-0"> */}
        <nav class="container navbar navbar-expand-lg fixed-top navbar-light px-4 py-3 py-lg-0">
          <a href={`${process.env.PUBLIC_URL}`} class="navbar-brand p-0">
            <img src={`${process.env.PUBLIC_URL}/assets/img/boithok-logo.png`} alt="Logo" />
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="fa fa-bars"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto py-0">
              <Link to="/" class="nav-item nav-link active">Home</Link>
              <Link to="/about" class="nav-item nav-link">About Boithok</Link>
            </div>
            {
              localStorage.getItem('token') ? (
                <div class="nav-item dropdown">
                  <a href="#" class="nav-link px-0 dropdown-toggle" data-bs-toggle="dropdown">Welcome, {localStorage.getItem('name')}</a>
                  <div class="dropdown-menu m-0">
                    <Link to="/user_dashboard" class="dropdown-item">Meeting Dashboard</Link>
                    <Link to="/profile" class="dropdown-item">Update Profile</Link>
                    <Link to="/change_password" class="dropdown-item">Change Password</Link>
                    <div style={divider}></div>
                    <a onClick={handleLogout} class="dropdown-item"><i class="bi bi-box-arrow-right"></i> Logout</a>
                  </div>
                </div>
              )
                :
                (
                  <>
                    <Link to="/registration" class="btn btn-primary rounded-pill text-white py-2 px-4 me-4">Sign Up</Link>
                    <Link to="/login" class="btn btn-light border border-primary rounded-pill text-primary py-2 px-4 me-0">Log In</Link>
                  </>

                )
            }

          </div>
        </nav>
      </div>
      {/* </header> */}
    </>
  );
}
